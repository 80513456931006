.renderer--audio {
  display: flex;
  flex-direction: column;
  line-height: 0;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  .thumbnail {
    width: 100%;
    aspect-ratio: 16 / 8;
    object-fit: cover;
  }
  audio {
    width: 100%;
    background: rgba(255, 255, 255, 0.6);

    &.audioWithThumbnail {
      margin-top: -80px;
    }
  }
}
