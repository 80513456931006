.renderer--page {
  line-height: 0;
  h2 {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 40px;
    width: 100%;
    text-align: center;
    font-family: 'Alfa Slab One';
    font-weight: 500;
  }

  img {
    width: 100%;
  }
}
