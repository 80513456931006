.tabs {
}

.tabs-list {
  min-width: 400px;
  background-color: var(--color-gray3);
  border-radius: var(--border-radius);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  //box-shadow: 0 4px 30px #24292f;
}

.tab {
  color: black;
  cursor: pointer;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;

  &:hover {
    // background-color: rgba(#3399ff, 0.8);
  }

  &:focus {
    color: #fff;
  }

  &.Mui-selected {
    background-color: black;
    color: white;
  }

  &.Mui-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.tab-panel {
  padding: 20px 12px;
  border: 1px solid rgba(#fff, 0.4);
  border-radius: 12px;
}
