@import 'scss/breakpoints';

.chatWrapper {
  position: fixed;
  bottom: 0;
  height: 454px;
  margin-bottom: -424px;
  transition: margin-bottom 0.4s;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  overflow: hidden;
  z-index: 99;
  border-left: 2px solid var(--color-gray4);
  border-right: 2px solid var(--color-gray4);
  box-sizing: border-box;
  z-index: 999;

  .chatWrapper__header {
    display: flex;
    align-items: center;
    background-color: var(--color-gray4);
    flex-shrink: 0;
    width: 100%;
    padding: 4px 0 4px 10px;
    color: white;
    backdrop-filter: blur(4px);
    box-sizing: border-box;

    span {
      font-size: 14px;
      font-weight: 900;
    }

    .icon--arrowDown {
      margin-left: auto;
      height: 14px;
      width: 14px;
      transform: rotateX(180deg);
      fill: white;
      border-radius: 50%;
      padding: 5px;
      overflow: visible;
      &:hover {
        background-color: var(--color-gray4);
      }
    }
    &:hover {
      cursor: pointer;
      background-color: var(--color-gray5);
    }
    .chatGroupToggle {
      position: relative;
      display: none;
      margin-left: auto;
      width: 40px;
      height: 22px;
      background-color: var(--color-gray3);
      color: white;
      border-radius: var(--border-radius);
      box-sizing: border-box;

      .icon--private {
        position: absolute;
        top: -1px;
        right: 5px;
        width: 11px;
        stroke: transparent;
        fill: var(--color-gray4);
      }
      .icon--public {
        position: absolute;
        top: -1px;
        left: 0;
        width: 12px;
        stroke: transparent;
        fill: white;
      }

      .chatGroupToggle__marker {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 20px;
        height: 20px;
        background: var(--color-gray4);
        border-radius: 50%;
        transition: left 0.4s;
      }
      &:hover {
        cursor: pointer;
        background-color: white;
      }

      &.chatGroupToggle--right {
        .chatGroupToggle__marker {
          left: 19px;
        }
        .icon--private {
          fill: white;
        }
        .icon--public {
          fill: var(--color-gray4);
        }
      }
    }
  }

  iframe {
    background: black;
    border: 0;
    height: 420px;
    width: 100%;
  }

  &.chatWrapper--visible {
    margin-bottom: 0;
    .icon {
      margin-left: var(--spacing-xs);
      transform: rotateX(0deg);
    }
    .chatGroupToggle {
      display: flex;
    }
  }
}

[theme='dark'] {
  .chatWrapper {
    filter: invert(100%);
    iframe {
      filter: invert(0%);
    }
  }
}
