.renderer--article {
  text-align: left;

  p {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
    // text-align: left;

    img {
      display: block;
      // margin: auto;
      margin-top: var(--spacing-l);
      margin-bottom: var(--spacing-l);
      max-width: 100%;
      border-radius: var(--border-radius);
    }
  }

  .page {
    width: 100%;

    h6 {
      margin-top: 4px;
      text-align: center;
    }
  }

  .renderer--article__summary {
    font-weight: 900;
    text-align: center;
  }
  .renderer--article__meta {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-xl);
    text-align: center;
  }
  .renderer--article__text {
    overflow-y: scroll;
    a {
      color: black;
    }

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
}
