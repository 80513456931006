.renderer--post {
  background: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0;

  .imageWrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background: black;
    overflow: hidden;

    .imageWrapper__background {
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(10px);
      opacity: 0.4;
    }
    img {
      position: absolute;
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: contain;
      z-index: 1;
    }
  }

  video {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: black;
  }
}
