@import 'scss/breakpoints';

@font-face {
  font-family: 'Alfa Slab One';
  src:
    local('Alfa Slab One'),
    url('./assets/fonts/AlfaSlabOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src:
    local('Poppins'),
    url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.flip {
  filter: invert(0%);
  // transition: filter 0.4s;
}

.hidden {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.navLink,
a {
  text-decoration: none;
  color: inherit;
}

[theme='dark'] {
  .flip {
    filter: invert(100%);
    //     transition: filter 0.4s;
  }
}

:root {
  --spacing-xxs: 2px;
  --spacing-xs: 5px;
  --spacing-s: 10px;
  --spacing-m: 20px;
  --spacing-l: 30px;
  --spacing-xl: 40px;
  --border-radius: 14px;

  --color-text: #000000;
  --color-background-base: 249, 254, 255;
  --color-background: rgba(var(--color-background-base), 1);
  --color-border: #000000;
  --color-gray1: rgba(235, 241, 245, 0.7);
  --color-gray2: rgba(234, 238, 245, 0.9);
  --color-gray3: #e3e9f4;
  --color-gray4: rgba(0, 45, 70, 0.5);
  --color-gray5: rgba(0, 45, 70, 0.6);
}

body[theme='dark'] {
  --color-text: #ffffff;
  --color-background-base: 255, 254, 249;
  --color-background: rgba(var(--color-background-base), 1);
  --color-border: #ffffff;
  --color-gray1: rgba(173, 159, 137, 0.2);
  --color-gray2: rgba(173, 159, 137, 0.3);
  --color-gray3: #e1dbd2;
  --color-gray4: rgba(70, 0, 0, 0.5);
  --color-gray5: rgba(70, 0, 0, 0.7);
}
