.renderer {
  width: 100%;

  h2 {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 40px;
    width: 100%;
    // text-align: center;
    font-family: 'Alfa Slab One';
    font-weight: 500;
  }

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    margin-bottom: var(--spacing-s);
    margin-left: 0;

    div {
      width: 100%;
    }

    img {
      border-radius: var(--border-radius);
      min-width: 50%;
      margin-bottom: var(--spacing-s);
    }

    figcaption {
      width: 50%;
      margin-top: 4px;
      // text-align: center;
      text-align: left;
      margin-bottom: var(--spacing-l);
    }
  }

  picture {
    display: inline-block;
    width: 100%;

    img {
      width: 100%;
      border-radius: var(--border-radius);
    }
  }
}
