.assetMeta {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-gray1);
  border-radius: var(--border-radius);
  padding: var(--spacing-s);
  margin-top: var(--spacing-m);
  box-sizing: border-box;
}

.assetMeta__header {
  display: flex;
  align-items: center;

  .avatar {
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    margin-right: var(--spacing-s);
  }
  .avatar--default {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 34px;
      height: 34px;
      margin-top: 2px;
    }
  }
  .assetMeta__handle {
    font-weight: 900;
    margin-right: var(--spacing-s);
  }
}

.assetMeta__stampers {
  display: flex;
  margin-top: var(--spacing-s);
  height: 32px;
  background: var(--color-gray3);
  border-radius: 16px;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
    padding: 4px;
    fill: #ff8543;
    background: var(--color-gray3);
    border-radius: 50%;
    margin-right: var(--spacing-s);
  }
  .avatar {
    margin-left: -5px;
    border: 2px solid black;
    z-index: 1;
    &:hover {
      cursor: pointer;
      z-index: 2;
    }
  }
}

[theme='dark'] {
  .assetMeta__stampers {
    svg {
      fill: #007bb3;
    }
  }
}
