.stampButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin: 0;

  svg {
    width: 24px;
    height: 24px;
    user-select: none;
    box-sizing: border-box;
    fill: white;
    strokewidth: 1px;

    &:hover {
      cursor: pointer;
      fill: #ff8543;
    }
  }
}

.stampButton--stamping {
  svg {
    fill: gray;
  }
}

.stampButton--stamped {
  svg {
    fill: #ff8543;
  }
}

[theme='dark'] {
  .stampButton {
    &:hover {
      svg {
        fill: #007bb3;
      }
    }
  }
  .stampButton--stamped {
    svg {
      fill: #007bb3;
      &:hover {
        fill: #007bb3 !important;
      }
    }
  }
}
