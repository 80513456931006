.metaRenderer {
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  overflow: hidden;
}

.metaRenderer__header {
  display: flex;
  height: 34px;
  width: 100%;
  background: var(--color-gray4);
  align-items: center;
  overflow: hidden;
}

.metaRenderer__id {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  margin-left: var(--spacing-xs);
  color: white;
  position: relative;
  max-width: calc(30% - 48px);

  p {
    padding: 0;
    margin: 0;
    padding-left: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
  }

  svg {
    stroke: white;
    fill: white;
    margin-left: 6px;
    flex-shrink: 0;
    margin-right: var(--spacing-s);
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    color: black;
    svg {
      stroke: black;
      fill: black;
    }
  }
}

.metaRenderer__tabs {
  display: flex;
  margin-left: auto;
  height: 100%;
}

.metaRenderer__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-gray3);
  padding: 0 6px;
  min-width: 20px;
  margin: 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 900;
  user-select: none;
  flex-shrink: 0;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    font-family: 'Alfa Slab One';
    font-weight: 500;
    background: gray;
    color: white;
    border-radius: 4px;
  }

  img,
  svg {
    height: 18px;
    max-width: 18px;
    border-radius: 4px;
  }

  span {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
  }

  .padding {
    svg {
      color: white;
      padding: 2px;
      box-sizing: border-box;
    }
  }

  &:hover {
    cursor: pointer;
    background: white;
  }

  &.metaRenderer__tab--active {
    background: white;
    &:hover {
      cursor: default;
    }
  }
}

.metaRenderer__scale {
  display: flex;
  width: 26px;
  min-width: 26px;
  height: 26px;
  margin-left: var(--spacing-xxs);
  margin: 2px;
  border-radius: 50%;
  flex-shrink: 0;
  transform: rotateX(0deg);
  transition: transform 0.4s;
  .icon {
    stroke: white;
    fill: white;
    padding: 5px;
    margin-top: -6px;
  }
  .icon--maximize {
    margin-top: -4px;
    stroke: transparent;
  }
  &:hover {
    cursor: pointer;
    background: var(--color-gray4);
  }

  &.metaRenderer__scale--minimize {
    transform: rotateX(180deg);
  }
}

.rendererWrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  max-height: 900px;
  transition: max-height 0.4s;
  border-left: 2px solid var(--color-gray4);
  border-right: 2px solid var(--color-gray4);
  border-bottom: 2px solid var(--color-gray4);
  box-sizing: border-box;
  border-radius: 0 0 18px 18px;

  &.rendererWrapper--minimized {
    max-height: 0;
    border-bottom: 0px solid var(--color-gray4);
  }

  iframe {
    width: 100%;
    max-height: 900px;
    box-sizing: border-box;
    aspect-ratio: 16 / 9;
    border: 0;
    background: white;
  }
}

[theme='dark'] {
  .metaRenderer {
    .metaRenderer__header {
      p {
        color: black;
        filter: invert(100%);
      }
      .metaRenderer__tab {
        img,
        div {
          filter: invert(100%);
        }
      }
    }
  }
  .renderer,
  iframe,
  audio {
    filter: invert(100%);
    iframe {
      filter: invert(0%);
    }
  }
}
