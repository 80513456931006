$BODY_PADDING: 20px;

@mixin buttonStyle {
  width: 120px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid black;
  font-size: 18px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  background: transparent;

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray3);
  }
}

.uploadAsset {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.uploadAsset--disabled {
  pointer-events: none;
  opacity: 0.4;
}

.uploadAsset__spinner {
  @keyframes loading {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  margin-top: var(--spacing-m);
  height: 4px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: loading 4s ease-in-out infinite;
  border-radius: 2px;
}

.uploadAsset__header {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
  font-weight: bold;
  font-size: smaller;
  margin-bottom: var(--spacing-m);

  .avatar {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.uploadAsset__body {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  justify-content: center;
  padding: 0 $BODY_PADDING;
  min-width: 450px;
}

.uploadAsset__field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-weight: bold;
    font-size: larger;
  }

  input {
    height: 2rem;
    border-radius: 5px;
    background-color: var(--color-gray3);
    padding: 0 var(--spacing-s);
    border: none;
  }

  textarea {
    resize: none;
    height: 6rem;
    border-radius: 5px;
    background-color: var(--color-gray3);
    padding: var(--spacing-s);
    border: none;
  }

  .radioField {
    display: flex;
    align-items: center;

    input {
      appearance: none;
      -webkit-appearance: none;

      height: 1rem;
      width: 1rem;
      border: 2px solid #555555;
      border-radius: 50%;
      background-clip: content-box;
      padding: 3px;

      &:checked {
        background-color: black;
      }

      &:hover {
        cursor: pointer;
      }
    }

    label {
      padding-top: 5px; // ugh
      font-weight: unset;
      font-size: smaller;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.uploadAsset__field--radio {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-s);
  justify-content: flex-start;
}

.uploadAsset__field--select {
  select {
    width: 100%;
    padding: 9px;
    border-radius: 4px;
    border: 1px solid #ccc;
    // appearance: none;
    background-color: var(--color-gray3);
  }
}

.uploadAsset__submit {
  display: flex;
  justify-content: space-between;
  padding: 0 $BODY_PADDING;
  margin-top: var(--spacing-xl);

  button {
    @include buttonStyle;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }

    &.primary {
      background-color: black;
      color: white;
    }
  }
}

.uploadAsset__fileSelect {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
  padding: 30px 0;
  background-color: var(--color-gray3);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  outline: 1px dashed var(--color-gray4);

  color: gray;
}
