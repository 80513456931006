.assetHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-top: var(--spacing-s) !important;
    margin-bottom: var(--spacing-s) !important;
    text-align: center;
  }
  .assetHeader__banner {
    border-radius: var(--border-radius);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    text-align: center;
    padding-bottom: var(--spacing-m);
    margin-bottom: var(--spacing-l);
    border-bottom: 1px solid var(--color-gray3);
  }
}

[theme='dark'] {
  h2,
  p {
    color: white;
    filter: invert(100%);
  }
  .assetHeader__banner {
    filter: invert(100%);
  }
}
