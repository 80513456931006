.files {
  margin: 6px 0 0 0;
  padding: 10px;

  display: grid;
  grid-gap: 10px;
  border-radius: var(--border-radius);
  background-color: var(--color-gray3);
  grid-template-columns: repeat(6, 1fr);
}

.files__item {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  flex: 1;

  img,
  video {
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray2);
    cursor: default;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.files__itemActions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}
