.renderer--collection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.collectionGrid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--spacing-s);
  padding: var(--spacing-s) 0;
  width: 100%;
  aspect-ratio: 16 / 9;
  box-sizing: border-box;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-gray2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 70, 0.5);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  scrollbar-width: unset !important;
  scrollbar-color: var(--color-gray2) !important;

  & *::-webkit-scrollbar-thumb {
    background-color: var(--color-text);
  }

  & *::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
}

.collectionGrid__tileWrapper {
  position: relative;
  flex: 0 0 calc(33.333% - var(--spacing-s));
  aspect-ratio: 16 / 9;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.collectionGrid__tile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  border-radius: var(--border-radius);
  overflow: hidden;

  &.collectionGrid__tile--image {
    display: flex;
    justify-content: center;
    background-color: black;

    .collectionGrid__tile--imageBackcground {
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(10px);
      opacity: 0.4;
      z-index: 0;
    }

    img {
      max-height: 100%;
      object-fit: cover;
      z-index: 1;
      transition: transform 0.4s !important;
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
    label {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.4);
      padding: 4px;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      color: black;
      backdrop-filter: blur(4px);
      font-size: 12px;
      text-align: center;
      z-index: 1;
      overflow: hidden;
    }
  }

  &.collectionGrid__tile--expanded {
    transition:
      left 0.4s,
      right 0.4s,
      top 0.4s,
      bottom 0.4s,
      width 0.4s,
      height 0.4s;
    top: 62px !important;
    left: var(--spacing-m) !important;
    right: var(--spacing-m) !important;
    bottom: var(--spacing-m) !important;
    width: calc(100% - (var(--spacing-m) * 2)) !important;
    height: calc(100% - (var(--spacing-m) + 62px)) !important;
    z-index: 2;
    overflow: hidden;
    img {
      transform: scale(1) !important;
    }

    .collectionGrid__fullscreenPreviews {
      position: absolute;
      bottom: 5%;
      width: 80%;
      height: 14%;
      // background:red;
      z-index: 7;
    }
  }
}
