@mixin buttonStyle {
  width: 120px;
  height: 40px;
  border-radius: 50px;
  border-style: unset;
  background-color: var(--color-gray1);
  font-size: 18px;
  font-weight: 600;
  color: black;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray3);
    color: white;
  }
}

.profileEdit {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.profileEdit--disabled {
  pointer-events: none;
  opacity: 0.4;
}

.profileEdit__header {
  font-size: xx-large;
  font-weight: bold;
}

.profileEdit__body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.profileEdit__lhs {
  height: fit-content;
  min-width: 450px;
  width: calc(40% - var(--spacing-s));
  padding: var(--spacing-s) var(--spacing-s) 0 var(--spacing-s);
}

.profileEdit__rhs {
  height: fit-content;
  width: 500px;
  box-sizing: border-box;
  flex: 1;
}

.profileEdit__uploads {
  width: 100%;
  position: relative;

  .banner {
    width: 100%;
    height: 150px;
    background: lightgray;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background: gray;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      color: white;
      font-weight: 600;
      text-shadow: 0 0 5px black;
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: lightgray;
    border: 1px solid var(--color-gray3);
    position: absolute;
    left: 5%;
    transform: translate(0, -100%);

    &:hover {
      cursor: pointer;
      background: gray;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.2rem;
      color: white;
      font-weight: 600;
      text-shadow: 0 0 5px black;
    }
  }
}

.profileEdit__uploadPrice {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: var(--spacing-s);
  font-family: monospace;
  color: blue;
}

.profileEdit__removals {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing-s);

  button {
    @include buttonStyle;
    font-size: smaller;
  }
}

.profileEdit__preview {
  background: lightgray;
  border-radius: 5px;
  margin-top: var(--spacing-m);
  padding: var(--spacing-s);
  max-width: 100%;

  > * {
    display: flex;
    flex-direction: column;
  }

  label {
    font-size: 0.7rem;
    opacity: 0.7;

    &:not(:first-child) {
      margin-top: var(--spacing-s);
    }
  }

  span {
    min-height: 1rem;
  }
}

.profileEdit__list {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-m);
  gap: var(--spacing-s);
}

.profileEdit__field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input {
    height: 2rem;
    border-radius: 5px;
    border: 1px solid var(--color-gray3);
    padding: 0 var(--spacing-s);
  }

  textarea {
    resize: none;
    height: 6rem;
    border-radius: 5px;
    border: 1px solid var(--color-gray3);
    padding: var(--spacing-s);
  }
}

.profileEdit__submit {
  display: flex;
  margin-left: auto;
  margin-top: var(--spacing-m);

  input[type='submit'] {
    @include buttonStyle;
    margin-left: auto;
  }

  button {
    @include buttonStyle;
    background: none;
    font-weight: 400;

    &:hover {
      cursor: pointer;
      background: none;
      color: black;
      font-weight: bold;
    }
  }
}

.profileEdit__error {
  font-size: 0.8rem;
  color: crimson;
  padding-left: var(--spacing-s);
}
