.toasts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);

  position: fixed;
  z-index: 5500;
  right: 16px;
  bottom: 16px;
  left: auto;
  justify-content: flex-start;
  max-width: 560px;
  min-width: 300px;
}

@keyframes in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.toast {
  display: flex;
  flex-direction: column;

  max-width: 560px;
  min-width: 300px;
  background-color: #24292f;
  border-radius: 8px;
  border: 1px solid #424a53;
  //box-shadow: 0 4px 8px rgb(0 0 0 / 0.7);
  padding: 1rem;
  animation: in-right 200ms;
  transition: transform 0.2s ease-out;
  font-size: 1rem;
  font-weight: bold;
  color: #bdebf4;
}

.toast__sub-message {
  font-size: 0.8rem;
  font-weight: normal;
  color: gray;
}

.toast__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem 1rem 0.5rem 0;
  cursor: pointer;
  color: #a8a8a8; // #bdebf4;
}
