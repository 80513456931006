.listToggle {
  position: relative;
  display: flex;
  align-items: center;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--color-gray3);
  color: black;
  padding: 4px;
  box-sizing: border-box;
  z-index: 0;
  user-select: none;
  outline: 2px solid var(--color-background);

  .listToggle__entry {
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    z-index: 1;
    transition: color 0.4s;

    &:nth-of-type(1) {
      left: 15px;
    }
    &:nth-of-type(2) {
      left: 70px;
    }
    &:nth-of-type(3) {
      right: 15px;
    }

    &.listToggle__entry--active {
      color: white;
      &:hover {
        cursor: default;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .listToggle__marker {
    position: absolute;
    width: 56px;
    height: 30px;
    background-color: black;
    border-radius: 50px;
    z-index: 0;
    left: 4px;
    transition:
      left 0.4s,
      width 0.4s;

    &.listToggle__marker--middle {
      left: 60px;
      width: 67px;
    }

    &.listToggle__marker--right {
      left: 128px;
      width: 48px;
    }
  }
}
