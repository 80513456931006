.renderer--image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .imageWrapper {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
    background-color: black;
    aspect-ratio: 16 / 9;

    div {
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(10px);
      opacity: 0.4;
    }
    img {
      height: 100%;
      z-index: 1;
    }
  }
}
